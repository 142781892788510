
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import { Node, Organization } from "@stellarbeat/js-stellar-domain";
import {
  BFormInput,
  BIconShield,
  BPagination,
  BTable,
  VBTooltip,
} from "bootstrap-vue";

@Component({
  components: {
    BFormInput: BFormInput,
    BTable: BTable,
    BIconShield: BIconShield,
    BPagination: BPagination,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
})
export default class AddOrganizationsTable extends Vue {
  @Prop()
  public organizations!: Organization[];

  public mode = "multi";
  public optionShowInactive = 1;
  public sortBy = "index";
  public sortDesc = true;
  public perPage = 10;
  public currentPage = 1;
  public filter = "";
  public totalRows = 1;
  public fields = [
    { key: "name", sortable: true },
    { key: "availability", sortable: true, label: "30D availability" },
  ];

  rowSelected(items: Node[]) {
    this.$emit("organizations-selected", items);
  }

  public onFiltered = (filteredItems: unknown[]) => {
    this.totalRows = 1;
    //@ts-ignore
    this.$refs.paginator._data.currentPage = 1;
    //@ts-ignore
    this.$refs.paginator._data.localNumPages = Math.round(
      filteredItems.length / this.perPage
    );
    //reactivity doesn't work on currentPage and totalRows. why?
  };

  mounted() {
    // Set the initial number of items
    this.totalRows = this.organizations.length;
  }
}
