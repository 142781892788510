
import { Component, Mixins } from "vue-property-decorator";
import { BTooltip, BIconInfoCircle, BBadge } from "bootstrap-vue";
import { Chart } from "chart.js";
import { StoreMixin } from "@/mixins/StoreMixin";
import { IsLoadingMixin } from "@/mixins/IsLoadingMixin";
import moment from "moment";
import NetworkStatisticsAggregation from "@stellarbeat/js-stellar-domain/lib/network-statistics-aggregation";
import NetworkStatisticsChart from "@/components/network/cards/network-statistics/network-statistics-chart.vue";
import NetworkStatisticsCard from "@/components/network/cards/network-statistics/network-statistics-card.vue";

@Component({
  components: {
    NetworkStatisticsCard,
    NetworkStatisticsChart,
    BTooltip,
    BIconInfoCircle,
    BBadge,
  },
})
export default class NetworkStatistics extends Mixins(
  StoreMixin,
  IsLoadingMixin
) {
  public chart!: Chart;
  protected initialDataLoaded = false;
  protected yearStatistics: NetworkStatisticsAggregation[] = [];

  public async mounted() {
    if (!this.store.isSimulation) {
      let oneYearAgo = moment(this.network.time).subtract(1, "y").toDate();
      this.yearStatistics =
        await this.store.networkMeasurementStore.getMonthStatistics(
          "stellar-public",
          oneYearAgo,
          this.network.time
        );
    }
    this.isLoading = false;
    this.initialDataLoaded = true;
  }
}
