
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Store from "@/store/Store";
import moment from "moment";
import ClientOnly from "vue-client-only";
import {
  BIconClock,
  BIconCalendar,
  BFormTimepicker,
  BFormDatepicker,
  VBTooltip,
} from "bootstrap-vue";

@Component({
  name: "crawl-time",
  components: {
    BIconClock,
    BIconCalendar,
    BFormTimepicker,
    BFormDatepicker,
    ClientOnly,
  },
  directives: { "b-tooltip": VBTooltip },
})
export default class CrawlTime extends Vue {
  protected time: Date = new Date(this.store.network.time.getTime());
  protected crawlTime: string = moment(this.time).format("HH:mm:ss");
  protected minSelectedDate: Date = this.store.measurementsStartDate;

  get store(): Store {
    return this.$root.$data.store;
  }

  public timeTravel() {
    this.$router.push({
      name: this.$route.name ? this.$route.name : undefined,
      params: this.$route.params,
      query: {
        view: this.$route.query.view,
        "no-scroll": "1",
        network: this.$route.query.network,
        at: moment(this.time)
          .hours(Number(this.crawlTime.substr(0, 2)))
          .minutes(Number(this.crawlTime.substr(3, 2)))
          .toISOString(),
      },
    });
  }
}
