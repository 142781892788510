import { render, staticRenderFns } from "./network-statistics-chart.vue?vue&type=template&id=576510ff&scoped=true&"
import script from "./network-statistics-chart.vue?vue&type=script&lang=ts&"
export * from "./network-statistics-chart.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "576510ff",
  null
  
)

export default component.exports