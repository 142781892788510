
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Store from "@/store/Store";
import {
  BIconArrowClockwise,
  BIconArrowCounterclockwise,
  VBTooltip,
} from "bootstrap-vue";

@Component({
  name: "UndoRedo",
  components: {
    BIconArrowCounterclockwise: BIconArrowCounterclockwise,
    BIconArrowClockwise: BIconArrowClockwise,
  },
  directives: { "b-tooltip": VBTooltip },
})
export default class UndoRedo extends Vue {
  get store(): Store {
    return this.$root.$data.store;
  }

  protected onUndoUpdate() {
    this.store.undoUpdate();
    this.resetRouteIfNoSelectedNode();
  }

  protected onRedoUpdate() {
    this.store.redoUpdate();
  }

  protected onReset() {
    this.store.resetUpdates();
    this.resetRouteIfNoSelectedNode();
  }

  protected resetRouteIfNoSelectedNode() {
    if (
      this.store.selectedNode &&
      !this.store.network.getNodeByPublicKey(this.store.selectedNode.publicKey)
    ) {
      this.$router.push({
        name: "network-dashboard",
        query: {
          "no-scroll": "1",
          network: this.$route.query.network,
          view: this.$route.query.view,
          at: this.$route.query.at,
        },
      });
    }
  }
}
