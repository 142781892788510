
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Store from "@/store/Store";
import StellarCoreConfigurationGenerator from "@stellarbeat/js-stellar-domain/lib/stellar-core-configuration-generator";
import SimulateNewNode from "@/components/node/tools/simulation/simulate-new-node.vue";
import ValidatorsDropdown from "@/components/network/sidebar/validators-dropdown.vue";
import NavLink from "@/components/side-bar/nav-link.vue";
import OrganizationsDropdown from "@/components/network/sidebar/organizations-dropdown.vue";
import SideBar from "@/components/side-bar/side-bar.vue";

import { BIconX, BModal, VBModal, BBadge, VBTooltip } from "bootstrap-vue";
import ModifyNetwork from "@/components/network/tools/modify-network.vue";
import Stellar from "@/components/organization/logo/stellar.vue";

@Component({
  components: {
    Stellar,
    ModifyNetwork,
    SideBar,
    OrganizationsDropdown,
    NavLink,
    SimulateNewNode,
    ValidatorsDropdown,
    BModal,
    BIconX: BIconX,
    BBadge,
  },
  directives: { "b-modal": VBModal, "b-tooltip": VBTooltip },
})
export default class NetworkSideBar extends Vue {
  protected validatorsExpanded: boolean =
    this.networkTransitiveQuorumSetOrganizations.length === 0;
  protected organizationsExpanded = true;

  get store(): Store {
    return this.$root.$data.store;
  }

  get network() {
    return this.store.network;
  }

  //todo: move to domain
  get networkTransitiveQuorumSetNodes() {
    return Array.from(
      this.network.nodesTrustGraph.networkTransitiveQuorumSet
    ).map((publicKey) => this.network.getNodeByPublicKey(publicKey));
  }

  //todo: move to domain
  get networkTransitiveQuorumSetOrganizations() {
    return [
      ...new Set(
        this.networkTransitiveQuorumSetNodes
          .filter((node) => node && node.organizationId)
          .map((node) =>
            this.network.getOrganizationById(node.organizationId as string)
          )
      ),
    ];
  }

  get tomlNodesExport() {
    let stellarCoreConfigurationGenerator =
      new StellarCoreConfigurationGenerator(this.network);
    return stellarCoreConfigurationGenerator.nodesToToml(
      this.networkTransitiveQuorumSetNodes
    );
  }

  scrollToHorizonCard() {
    this.$scrollTo("#public-horizon-apis-card");
  }
}
