
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import Search from "@/components/search.vue";
import UndoRedo from "@/components/node/tools/simulation/UndoRedo.vue";
import Github from "@/components/organization/logo/github.vue";
import CustomNetwork from "@/components/network/tools/modify-network.vue";
import {
  BNavbar,
  BAlert,
  BCollapse,
  BIconBuilding,
  BIconBullseye,
  BIconHouse,
  BIconCode,
  BIconQuestionCircle,
  BIconEnvelope,
  BNavbarToggle,
  BFormSelect,
  BIconGlobe2,
  BNavItemDropdown,
  BDropdownItem,
  BIconNewspaper,
  BBadge,
  BIconBell,
} from "bootstrap-vue";
import Store from "@/store/Store";
import { Route } from "vue-router";
import { isString } from "@stellarbeat/js-stellar-domain/lib/typeguards";

@Component({
  name: "app",
  components: {
    BIconBell,
    Github,
    CustomNetwork,
    UndoRedo,
    Search,
    BNavbar,
    BNavItemDropdown,
    BDropdownItem,
    BAlert,
    BCollapse,
    BIconBuilding,
    BIconBullseye,
    BIconHouse,
    BIconCode,
    BIconQuestionCircle,
    BIconEnvelope,
    BNavbarToggle,
    BFormSelect,
    BIconGlobe2,
    BIconNewspaper,
    BBadge,
  },
  metaInfo: {
    title: "Stellarbeat.io - Stellar network visibility",
    meta: [
      {
        name: "description",
        content:
          "Giving insight into the Stellar network through various tools & visualizations.",
      },
    ],
  },
})
export default class App extends Vue {
  protected errorMessage =
    "Could not connect to stellarbeat.io api, please refresh the page";
  protected navCollapsed = false;
  protected enableNotify = process.env.VUE_APP_ENABLE_NOTIFY === "1";

  async created() {
    let networkId = this.$route.query.network;
    if (
      "string" === typeof networkId &&
      this.store.availableNetworks.includes(networkId)
    ) {
      this.store.networkId = networkId;
    }
    let timeAt = this.store.getDateFromParam(this.$route.query.at);
    await this.store.initializeNetwork(timeAt);
  }

  serverPrefetch() {
    let networkId = this.$route.query.network;
    if (
      "string" === typeof networkId &&
      this.store.availableNetworks.includes(networkId)
    ) {
      this.store.networkId = networkId;
    }
    let timeAt = this.store.getDateFromParam(this.$route.query.at);

    return this.store.initializeNetwork(timeAt);
  }

  @Watch("$route", { immediate: false })
  async onRouteChanged(to: Route) {
    let networkId = this.store.networkId;
    let timeTravelDate = this.store.getDateFromParam(to.query.at);
    let timeTravel = false;
    if (!timeTravelDate && this.store.isTimeTravel)
      //time travel reset
      timeTravel = true;
    if (timeTravelDate && !this.store.timeTravelDate) timeTravel = true;
    if (
      timeTravelDate &&
      this.store.timeTravelDate &&
      timeTravelDate.getTime() !== this.store.timeTravelDate.getTime()
    )
      timeTravel = true;

    if (
      isString(to.query.network) &&
      this.store.availableNetworks.includes(to.query.network)
    )
      networkId = to.query.network;

    if (networkId !== this.store.networkId || timeTravel) {
      this.store.networkId = networkId;
      this.store.isLoading = true;
      await this.$nextTick(async () => {
        //next tick is needed to toggle the loading state. The loading state is needed to clean up the previous gui.
        await this.store.initializeNetwork(
          timeTravel ? timeTravelDate : undefined
        );
      });
    }
  }

  get store(): Store {
    return this.$root.$data.store;
  }

  get network() {
    return this.store.network;
  }

  get showError() {
    return this.store.fetchingDataFailed;
  }

  get homeActiveClass() {
    return {
      active:
        this.$route.name === "network-dashboard" ||
        this.$route.name === "node-dashboard" ||
        this.$route.name === "organization-dashboard",
    };
  }

  navigateToNetwork(networkId: string) {
    if (networkId === this.store.networkId) return;

    this.$router
      .push({
        name: "network-dashboard",
        query: { network: networkId },
      })
      .catch(() => {
        //this triggers a navigation guard error that we can safely ignore. See router beforeEach
      });
  }
}
