
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import {
  BDropdown,
  BDropdownItem,
  BIconThreeDotsVertical,
  BDropdownText,
  BDropdownHeader,
  BIconXCircle,
  BIconGearWide,
  BIconClipboard,
  BIconLightning,
  BDropdownItemButton,
} from "bootstrap-vue";

import { Network, Node, QuorumSet } from "@stellarbeat/js-stellar-domain";
import Store from "@/store/Store";

@Component({
  components: {
    BDropdown,
    BDropdownItem,
    BDropdownText,
    BIconThreeDotsVertical,
    BDropdownHeader,
    BIconXCircle,
    BIconGearWide,
    BIconClipboard,
    BIconLightning,
    BDropdownItemButton,
  },
})
export default class NodeActions extends Vue {
  @Prop()
  public node!: Node;
  @Prop({ default: false })
  public supportsDelete!: boolean;
  @Prop()
  public quorumSet!: QuorumSet;
  @Prop({ default: true })
  public supportsHaltingAnalysis!: boolean;

  get store(): Store {
    return this.$root.$data.store;
  }

  get network(): Network {
    return this.store.network;
  }

  copyPublicKey() {
    this.$copyText(this.node.publicKey);
  }
}
