import { render, staticRenderFns } from "./UndoRedo.vue?vue&type=template&id=7e9edbfe&scoped=true&"
import script from "./UndoRedo.vue?vue&type=script&lang=ts&"
export * from "./UndoRedo.vue?vue&type=script&lang=ts&"
import style0 from "./UndoRedo.vue?vue&type=style&index=0&id=7e9edbfe&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e9edbfe",
  null
  
)

export default component.exports