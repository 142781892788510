
import { Component, Prop, Mixins } from "vue-property-decorator";
import { Node } from "@stellarbeat/js-stellar-domain";
import NavLink from "@/components/side-bar/nav-link.vue";
import { DropdownMixin } from "@/components/side-bar/dropdown-mixin";
import NavPagination from "@/components/side-bar/nav-pagination.vue";
import NodeActions from "@/components/node/sidebar/node-actions.vue";
import LazyHydrate from "vue-lazy-hydration";

@Component({
  components: {
    NodeActions,
    NavPagination,
    NavLink,
    LazyHydrate,
  },
})
export default class ValidatorsDropdown extends Mixins(DropdownMixin) {
  @Prop()
  public nodes!: Node[];

  get sortedNodes() {
    let sort = (nodeA: Node, nodeB: Node) => {
      return nodeA.displayName.localeCompare(nodeB.displayName);
    };

    let failingNodes = this.nodes
      .filter((node) => this.network.isNodeFailing(node))
      .sort(sort);
    let nonFailingNodes = this.nodes
      .filter((node) => !this.network.isNodeFailing(node))
      .sort(sort);
    return failingNodes.concat(nonFailingNodes);
  }

  get paginatedNodes() {
    return this.paginate(this.sortedNodes);
  }

  hasWarnings(node: Node) {
    return this.network.nodeHasWarnings(node);
  }

  get hasGeneralValidatorsWarning() {
    return this.nodes.some(
      (node) =>
        this.network.nodeHasWarnings(node) || this.network.isNodeFailing(node)
    );
  }

  get generalValidatorsWarning() {
    if (this.nodes.some((node) => this.network.isNodeFailing(node)))
      return "Some nodes are failing";

    if (this.nodes.some((node) => this.network.historyArchiveHasError(node)))
      return "Verification error in history archive";

    if (
      this.nodes.some((node) =>
        this.network.isFullValidatorWithOutOfDateArchive(node)
      )
    )
      return "Some history archives not up-to-date";

    return "";
  }

  public selectNode(node: Node) {
    this.$router.push({
      name: "node-dashboard",
      params: { publicKey: node.publicKey },
      query: {
        center: "1",
        "no-scroll": "0",
        view: this.$route.query.view,
        network: this.$route.query.network,
        at: this.$route.query.at,
      },
    });
  }

  public nodeState(node: Node) {
    return {
      inactive: !node.active,
      active: node.active,
      failing: this.network.isNodeFailing(node),
    };
  }

  getDisplayName(node: Node) {
    return node.displayName;
  }
}
